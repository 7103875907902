import React from "react";
import User from "../models/User";
import Message from "../models/Message";
import {toLocalDateTimeString} from "../functions/toLocalISO";

interface MessageListViewProps {
    messages: Message[],
    currentUser: User,
    endRef?: React.MutableRefObject<null | HTMLDivElement>,
}

export const MessageListView: React.FC<MessageListViewProps> = ({messages, currentUser, endRef}) => {
    return (
        <div style={{flex: 1, overflowY: 'scroll', width: '100%', paddingBottom: 32}}>
            {messages.map((message) => {
                const isMe = message.author.id === currentUser?.id;

                return (
                    <div key={message.id} style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isMe ? 'flex-end' : 'flex-start',
                        marginTop: 16,
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: isMe ? 'row-reverse' : 'row',
                            textAlign: 'start',
                            backgroundColor: isMe ? '#0000ff' : '#efefef',
                            color: isMe ? '#fff' : undefined,
                            borderBottomLeftRadius: isMe ? 40 : 0,
                            borderTopLeftRadius: isMe ? 40 : 0,
                            borderTopRightRadius: isMe ? 0 : 40,
                            borderBottomRightRadius: isMe ? 0 : 40,
                            marginLeft: isMe ? 32 : 0,
                            marginRight: isMe ? 0 : 32,
                            padding: '16px 24px',
                            fontSize: 16,
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-wrap',
                        }}>
                            {message.content}
                        </div>
                        <span style={{
                            fontSize: 14,
                            // color: theme.palette.grey['700'],
                            padding: 16,
                            paddingTop: 4,
                            paddingBottom: 0,
                            textAlign: 'center'
                        }}>
                            {message.author.name + ' - ' + toLocalDateTimeString(message.createdAt)}
                        </span>
                    </div>
                );
            })}
            <div ref={endRef}/>
        </div>
    )
}

export default MessageListView;