import React, {useEffect, useRef, useState} from 'react';
import MessageInput from "./components/MessageInput";
import {Container} from "@material-ui/core";
import Message from "./models/Message";
import MessageListView from "./components/MessageListView";
import randomInt from "./functions/randomInt";



const responses = [
    'I like it!   ...but...\nwhat if we changed the color a little?',
    'I like it!   ...but...\nwhat if we changed the text a little?',
    'I like it!   ...but...\nwhat if we made this part a component?',
    'I like it!   ...but...\nwhat if we removed this text right here?',
    'I like it!   ...but...\nwhat if we tried something completely different?',
    'I like it!   ...but...\nwhat if we followed google standards instead?',
]


const App = () => {
    const currentUser = {id: 'you', name: 'You'}
    const botUser = {id: 'bot', name: 'Michael'}

    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');


    const messagesEndRef: React.MutableRefObject<null | HTMLDivElement> = useRef(null);
    const messagesLength = messages?.length;
    useEffect(() => {
        messagesEndRef?.current?.scrollIntoView({behavior: 'auto'})
    }, [messagesLength]);


    const onSubmit = async (text: string) => {
        const message = {
            id: '' + messages.length,
            content: text,
            author: {id: currentUser.id, name: currentUser.name},
            createdAt: new Date(),
        };

        setMessages([...messages, message]);
        await setTimeout(() => {
            setMessages([...messages, message, {
                id: '' + (messages.length + 1),
                content: responses[randomInt(responses.length)],
                author: {id: botUser.id, name: botUser.name},
                createdAt: new Date(),
            }]);
        }, 400);

    }




    return (
        <Container maxWidth={"md"} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100vh',
            padding: 0,
        }}>
            <h1>Ask Michael what he thinks</h1>
            <MessageListView
                messages={messages}
                currentUser={currentUser}
                endRef={messagesEndRef}
            />

            <div style={{backgroundColor: '#F3F3F5', width: '100%'}}>
                <MessageInput
                    value={input}
                    onSubmit={onSubmit}
                    onChange={setInput}
                    placeholder={'What do you think about...'}
                />
            </div>
        </Container>
    );
}

export default App;
