import React, {useState} from "react";
import Input, {InputProps} from "@material-ui/core/Input/Input";

export interface MessageInputProps {
    value: string;
    onSubmit: (text: string) => void;
    onChange: (text: string) => void;
    placeholder?: string;
}

const MessageInput: React.FC<MessageInputProps> =
    ({
         value = "",
         onSubmit,
         onChange,
         placeholder = "Skriv din besked her...",
     }) => {

        const [shift, setShift] = useState(false);
        const [ctrl, setCtrl] = useState(false);

        const handleKeyDown: InputProps['onKeyDown'] = (e) => {
            if (e.key === 'Enter' && !shift && !ctrl) {
                e.preventDefault();
                if (value.trim().length === 0) {
                    onChange("");
                    return;
                }
                onSubmit(value);
                onChange("");
            }
            if (e.key === 'Shift') {
                setShift(true);
            } else if (e.key === 'Control') {
                setCtrl(true);
            }
        };

        const handleKeyUp: InputProps['onKeyUp'] = (e) => {
            if (e.key === 'Shift') {
                setShift(false);
            } else if (e.key === 'Control') {
                setCtrl(false);
            }
        };

        const handleChange: InputProps['onChange'] = e => onChange(e.target.value);


        return (
            <Input
                value={value}
                name="content"
                type="text"
                autoComplete="message"
                fullWidth
                multiline
                rowsMax="8"
                rows={4}
                placeholder={placeholder}
                disableUnderline={true}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                autoFocus
                style={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    padding: '8px 16px',
                    borderRadius: 24,
                    borderBottom: 'none',
                }}
            />
        );

    };

export default MessageInput;